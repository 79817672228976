import React from "react";
import { useStyle } from "../../../../../../../context/styleContext";

function AdminInfo({ admin, handleClick }) {
    const { colorText } = useStyle();
    return (
        <div className='client-info'>
            <p
                className={`client-info__text font-weight-bold ${colorText}`}
                onClick={handleClick ? () => handleClick(admin) : () => { }}>
                {admin.surname} {admin.name}11
            </p>
        </div>
    )
}

export default AdminInfo;