import React, { useEffect, useState } from "react";
import { useStyle } from "../../../context/styleContext";

/** Выбор месяца */
function YearSelector({ isFullYears = true, handleYear }) {
    const [year, setYear] = useState(''); // Добавлено состояние для года
    const [yearNow, setYearNow] = useState('');
    const [yearPrevious, setYearPrevious] = useState('');
    const [yearsOptions, setYearsOptions] = useState([]); // опции в селект для выбора года у суперадмина
    const { colorText } = useStyle();

    //Слушать изменения года
    function changeYear(event) {
        let year = event.target.value;
        setYear(year);
        handleYear && handleYear(year)
    }

    useEffect(() => {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear(); // Получение текущего года
        setYearNow(currentYear);
        handleYear && handleYear(currentYear)
        const previousYear = currentYear - 1; // Получение года предыдущего месяца
        setYearPrevious(previousYear);
    }, [])

    //Наполнить опции для селекта выбора года и месяцев у суперадмина
    useEffect(() => {
        //Год
        const currentYear = new Date().getFullYear();
        setYear(currentYear); // первоначально текущий год
        const options = [];
        for (let i = 0; i < 5; i++) {
            const yearValue = currentYear - i;
            options.push(
                <option key={yearValue} value={yearValue}>
                    {yearValue}
                </option>
            );
        }
        setYearsOptions(options);
    }, []);

    return (
        <div className="formadd__input-conainer">
            <select
                value={year}
                onChange={changeYear}
                className={`formadd__input formadd__input_name_image formadd__input-select ${colorText}`}
            >
                {isFullYears ?
                    yearsOptions
                    :
                    <option key={yearNow} value={yearNow}>
                        {yearNow}
                    </option>
                }
            </select>
            <span className={`formadd__text-error ${colorText}`} id="error-firstname">Выбор года</span>
        </div>
    )
}

export default YearSelector;