import React from "react";
import styled from "styled-components";

/**
 * Кнопка с обводкой (Outline Button).
 *
 * @param {Object} props - Пропсы компонента.
 * @param {string} props.text - Текст внутри кнопки.
 * @param {function} props.onClick - Функция, вызываемая при клике.
 * @param {"small" | "medium" | "large"} [props.size="medium"] - Размер кнопки (по умолчанию "medium").
 * @param {"light" | "dark"} [props.theme="light"] - Тема кнопки (по умолчанию "light").
 * @returns {JSX.Element} Компонент кнопки.
 */
const OutlineButton = ({ text, onClick, size = "medium", theme = "light" }) => {
    return <StyledButton onClick={onClick} size={size} theme={theme}>{text}</StyledButton>;
};

const StyledButton = styled.button`
    background: transparent;
    border: 2px solid ${({ theme }) => (theme === "light" ? "black" : "rgba(255, 255, 255, 0.7)")};
    border-radius: 10px;
    cursor: pointer;
    transition: all 0.3s ease;
    color: ${({ theme }) => (theme === "light" ? "black" : "rgba(255, 255, 255, 0.7)")};

    ${({ size }) => {
        switch (size) {
            case "small":
                return "padding: 5px 10px; font-size: 12px;";
            case "large":
                return "padding: 15px 30px; font-size: 18px;";
            default: // medium (по умолчанию)
                return "padding: 10px 20px; font-size: 16px;";
        }
    }}

    &:hover {
        background: ${({ theme }) => (theme === "light" ? "rgba(0, 0, 0, 0.1)" : "rgba(255, 255, 255, 0.2)")};
    }

    &:active {
        background: ${({ theme }) => (theme === "light" ? "rgba(0, 0, 0, 0.2)" : "rgba(255, 255, 255, 0.3)")};
    }
`;

export default OutlineButton;