import React from 'react';
import Selector from '../../../../commonComponents/selectors/Selector/Selector';
import Tabs from '../../../../commonComponents/Tabs/Tabs';
import { useStyle } from '../../../../context/styleContext';

/**
 * Переключатель опций поиска.
 *
 * @param {Object} props - Пропсы компонента.
 * @param {Array<{ text: string, onClick: function, mode: string }>} props.tabs - Массив объектов, описывающих каждый таб:
 *  - text: Текст таба.
 *  - onClick: Функция, вызываемая при клике на таб.
 *  - mode: Уникальный идентификатор таба.
 * @param {string} props.initialActiveTab - Значение mode таба, который активен по умолчанию.
 * @param {string} props.theme - Тема компонента, может быть "light" или "dark".
 * @returns {JSX.Element} Компонент табов.
 */
function Switcher({ select, handleSelect, tabs }) {
    const { theme } = useStyle();

    return (
        <div className='switcher-super-admin'>
            <Tabs
                theme={theme}
                initialActiveTab={select}
                tabs={tabs ?? [
                    { value: 'trainers', label: 'Тренеры' },
                    { value: 'clients', label: 'Клиенты' },
                    { value: 'admins', label: 'Админы' },
                ]}
                onClick={handleSelect}

            />
        </div>
    )
}

export default Switcher;