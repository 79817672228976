import { useState, useEffect } from 'react';

function useLogicFormTrainig({
    isOpen,
    closeForm,
    handleSuccessUpdate,
    selectedTraining,
}) {

    const [openedForm, setOpenedForm] = useState('FormChoiceActionTraining') // название открытой формы

    function handleCreate(newAbonement) {

    }

    function clickCloseForm() {
        closeForm();
        setTimeout(() => {
            setOpenedForm('FormChoiceActionTraining')
        }, 550);
    }

    function handleSuccessUpdateClient(updatedClient) {
        handleSuccessUpdate(updatedClient);
        clickCloseForm()
    }

    function handleAbonementRemove() {

    }

    //Управлять формами
    useEffect(() => {
        if(isOpen){
            setOpenedForm(selectedTraining ? 'FormChoiceActionTraining' : "FormAddOrUpdateTraining")
        }
    }, [selectedTraining, isOpen])


    return {
        openedForm,
        setOpenedForm,
        //Функции
        handleCreate,
        handleSuccessUpdateClient,
        clickCloseForm,
        handleAbonementRemove,
    };
}

export default useLogicFormTrainig;
