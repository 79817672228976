import React from "react";
import styled from "styled-components";
import OutlineButton from "../../../commonComponents/buttons/OutlineButton/OutlineButton";
import { useUserContext } from "../../../context/userContext";
import { useStyle } from "../../../context/styleContext";

/** Информация о админе */
function AdminInfo({ admin, onClick }) {
    const { userData } = useUserContext();
    const { theme, colorText } = useStyle();

    return (
        <div className="trainer-info">
            <div className="trainer-info__container-name">
                <p className={`trainer-info__text trainer-info__text_name ${colorText}`}>{admin && admin.name}</p>
                <p className={`trainer-info__text ${colorText}`}>{admin && admin.surname}</p>
            </div>
            {admin && admin.contactData && admin.contactData.email &&
                <p className={`trainer-info__text ${colorText}`}>{admin.contactData.email}</p>
            }
            {admin && admin.contactData && admin.contactData.phoneNumber &&
                <p className={`trainer-info__text ${colorText}`}>{admin.contactData.phoneNumber}</p>
            }
            {
                userData && ['superAdmin', 'admin'].includes(userData.role) &&
                <ContainerButton>
                    <OutlineButton text={'Действия'} size='small' onClick={onClick} theme={theme} />
                </ContainerButton>
            }
        </div >
    )
}

export default AdminInfo;

const ContainerButton = styled.div`
    margin-top: 10px;
    margin-right: auto;
`