import React from "react";
import FormRemove from "../../../../../../../commonComponents/forms/FormRemove/FormRemove";
import useLogicFormRemovetraining from "./logic/useLogicFormRemovetraining";

function FormRemoveTraining({ trainingId, handleSuccessRemoveTraining, closeForm }) {

    const {
        removeTraining,
        isLoading,
        validText, setValidText,
        isAllTrainings, setIsAllTraining,
    } = useLogicFormRemovetraining({ trainingId, handleSuccessRemoveTraining })

    return (
        <FormRemove
            onRemove={removeTraining}
            isLoading={isLoading}
            closeForm={closeForm}
            textForFill={validText}
            cleanTextForFill={() => setValidText()}
            isSelector={true}
            isAllTrainings={isAllTrainings}
            setIsAllTrainings={setIsAllTraining}
        />
    )
}

export default FormRemoveTraining;