import React from "react";
import ModalWithChildren from "../../../../commonComponents/modal/ModalWithChildren";
import FormChoiceActions from "../forms/FormChoice/FormChoiceActions";
//import FormRemoveFromBranch from "../forms/FormRemoveFromBranch/FormRemoveFromBranch";
import useLogicModalWithForms from "./logic/useLogicModalWithForms";
import FormAddorUpdateAdmin from "../../../ClientsAndEmployees/components/List/AdminsList/components/ModalWithForm/forms/FormAddorUpdateAdmin/FormAddorUpdateAdmin";
import FormRefreshPassword from "../../../TrainerPage/components/modalAndForms/forms/FormRefreshPassword/FormRefreshPassword";

function ModalWithForms({ isOpen, closeForm, handleSuccessUpdate, admin }) {

    const {
        openedForm, setOpenedForm,
        clickCloseForm,
    } = useLogicModalWithForms({ closeForm });

    return (
        <ModalWithChildren
            isOpen={isOpen}
            isClose={clickCloseForm}
            children={
                <>
                    {openedForm === 'FormChoiceActions' &&
                        < FormChoiceActions
                            openUpdateForm={() => setOpenedForm('FormAddorUpdate')}
                            openSendPasswordForm={() => setOpenedForm('FormRefreshPassword')}
                            openRemoveForm={() => setOpenedForm('FormRemoveFromBranch')}
                        />
                    }
                    {openedForm === 'FormAddorUpdate' &&
                        <FormAddorUpdateAdmin
                            admin={admin}
                            textButtonSubmit={'Редактировать'}
                            handleSuccessUpdate={handleSuccessUpdate}
                        />}
                    {openedForm === 'FormRefreshPassword' &&
                        <FormRefreshPassword
                            employee={admin}
                            closeForm={clickCloseForm}
                        />
                    }
                    {/* {openedForm === 'FormRemoveFromBranch' &&
                        <FormRemoveFromBranch
                            admin={admin}
                            closeForm={clickCloseForm}
                        />
                    } */}
                </>
            }
        />
    )
}

export default ModalWithForms;