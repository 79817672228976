import ButtonWithBorder from "../../buttons/ButtonWithBorder/ButtonWithBorder";
import Selector from "../../selectors/Selector/Selector";

function FormRemove({
    onRemove,
    closeForm,
    isLoading,
    textForFill, // текст для заполнения формы им 
    cleanTextForFill, // очистить текст для заолнения
    setIsAllTrainings,
    isAllTrainings,
    isSelector,
}) {

    return (
        <div className="form-remove">
            {textForFill ?
                <div>
                    <p>{textForFill}</p>
                    <ButtonWithBorder
                        text={'Назад'}
                        onClick={cleanTextForFill}
                    />
                </div>
                :
                <>
                    <p className="form-remove__title">Удалить</p>
                    {isSelector &&
                        <Selector
                            nameOne={'Применить ко всем тренировкам'}
                            handleOnClickOne={() => { setIsAllTrainings(!isAllTrainings) }}
                            switchControl={isAllTrainings} // внешний контроль отображения, true -вкл
                            onlyOn={false} // true - не возвращать селектор при повторном клике, можно только включать
                            styleSelector={'formadd__checkbox_super-admin'}
                        />}
                    <div className="form-remove__container-buttons">
                        <ButtonWithBorder text={'Да'} onClick={onRemove} isLoading={isLoading} />
                        <ButtonWithBorder text={'Нет'} onClick={closeForm} />
                    </div>
                </>
            }
        </div>

    )
}

export default FormRemove;