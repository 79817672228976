import { useEffect, useState } from "react";
import { useStudio } from "../../../context/studioContext";
import apiTrainingStatusSettings from "../../../api/apiTrainingStatusSettings";

function useList() {
    const { studio } = useStudio();
    const [statuses, setStatuses] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState();
    const [loading, setLoading] = useState(true);

    async function fetchStatuses() {
        try {
            setLoading(true);
            const res = await apiTrainingStatusSettings.getAll(studio._id);
            setStatuses(res.trainingStatuses);
        }
        catch (err) {
            console.log(err);
        }
        finally {
            setLoading(false);
        }
    }

    function selectStatus(status) {
        setSelectedStatus(status);
    }

    useEffect(() => {
        fetchStatuses();
    }, [])

    return ({
        statuses,
        selectedStatus,
        selectStatus,
        loading,
    })
}

export default useList;