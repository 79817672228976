const regexEmail = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.(ru|рф)$/;
const regexEmailEn = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+(\.[a-zA-Z0-9-_.]+|\.[а-яА-ЯёЁ0-9-_.]+)$/;
const regexPassword = /^[a-zA-Z0-9]+$/;
const regexVerificationCode = /^[a-zA-Z0-9]+$/;
const regexLogin = /^[a-zA-Zа-яА-Я0-9_\-.,]+$/u;
const regexStrokeInput = /^[a-zA-Zа-яА-Я0-9\s.,()-]*$/;
const regexNumericInput = /^[0-9.,]+$/;
const regexVIN = /^[A-HJ-NPR-Z0-9]{17}$/i;

function sanitizeInput(str) {
    return str
        .replace(/&/g, "&amp;")    
        .replace(/</g, "&lt;")
        .replace(/>/g, "&gt;")
        .replace(/"/g, "&quot;")
        .replace(/'/g, "&#039;")
        .replace(/\\/g, "\\\\")    
        .replace(/\$/g, "&#036;")  
        .replace(/\{/g, "&#123;")  
        .replace(/\}/g, "&#125;")
        .replace(/\[/g, "&#91;")   
        .replace(/\]/g, "&#93;")
        .replace(/@/g, "&#64;")    
        .replace(/:/g, "&#58;")    // Экранирование двоеточия
        .replace(/\|/g, "&#124;")  
        .replace(/\?/g, "&#63;")   
        .replace(/\./g, "&#46;")   // Экранирование точки
        .replace(/\\0/g, "");      
}
function decodeSanitizedInput(str) {
    return str
        .replace(/&amp;/g, "&")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#039;/g, "'")
        .replace(/&#036;/g, "$")
        .replace(/&#123;/g, "{")
        .replace(/&#125;/g, "}")
        .replace(/&#91;/g, "[")
        .replace(/&#93;/g, "]")
        .replace(/&#64;/g, "@")
        .replace(/&#58;/g, ":")   // Декодирование двоеточия
        .replace(/&#124;/g, "|")
        .replace(/&#92;/g, "\\")
        .replace(/&#63;/g, "?")   
        .replace(/&#46;/g, ".");  // Декодирование точки
}

export { regexEmail, regexPassword, regexVerificationCode, regexLogin, regexStrokeInput, regexNumericInput, regexVIN, regexEmailEn, decodeSanitizedInput, sanitizeInput }