import { useState } from "react";

function useForms() {
    const [form, setForm] = useState('');

    return ({
        form, setForm,
    })
}

export default useForms;