import React from "react";
import ButtonWithBorder from "../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import { Button, Label } from '@gravity-ui/uikit';
import OutlineButton from "../../../../commonComponents/buttons/OutlineButton/OutlineButton";
import styled from "styled-components";
import { useUserContext } from "../../../../context/userContext";
import { useStyle } from "../../../../context/styleContext";


/** Информация о тренере */
function TrainerInfo({ trainer, onClick }) {
    const { userData } = useUserContext();
    const { colorText, theme } = useStyle();

    return (
        <div className="trainer-info">
            <div className="trainer-info__container-name">
                <p className={`trainer-info__text trainer-info__text_name ${colorText}`}>{trainer && trainer.name}</p>
                <p className={`trainer-info__text ${colorText}`}>{trainer && trainer.surname}</p>
            </div>
            {trainer && trainer.contactData && trainer.contactData.email &&
                <p className={`trainer-info__text ${colorText}`}>{trainer.contactData.email}</p>
            }
            {trainer && trainer.contactData && trainer.contactData.phoneNumber &&
                <p className={`trainer-info__text ${colorText}`}>{trainer.contactData.phoneNumber}</p>
            }
            {userData && ['superAdmin'].includes(userData.role) &&
                <ContainerButton>
                    <OutlineButton text={'Действия'} size='small' onClick={onClick} theme={theme} />
                </ContainerButton>}
        </div>
    )
}

export default TrainerInfo;

const ContainerButton = styled.div`
    margin-top: 10px;
    margin-right: auto;
`