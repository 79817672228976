import React from "react";
import ButtonWithBorder from "../../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";

/** Форма для выбора работы с тренером */
function FormChoiceTrainerActions({
    openUpdateForm,
    openRemoveForm,
    refreshPassword,
}) {
    return (
        <> 
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Редактировать'} onClick={openUpdateForm} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Отправить пароль'} onClick={refreshPassword} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Удалить'} onClick={openRemoveForm} />
            </div>
        </>
    )
}

export default FormChoiceTrainerActions;