import React from "react";
import InputText from "../../../../../../../commonComponents/inputs/InputText/InputText";
import InputSelect from "../../../../../../../commonComponents/inputs/InputSelect/InputSelect";
import InputDateTimePicker from "../../../../../../../commonComponents/inputs/InputDateTimePicker/InputDateTimePicker";
import FormAdd from '../../../../../../../commonComponents/forms/FormAdd/FormAdd';
import useLogicFormAddOrUpdateTraining from "./logic/useLogicFormAddOrUpdateTraining";
import { trainingTypes } from "../../../../../utils/constants";
import Switcher from "../../../../../../ClientsAndEmployees/components/Switcher/Switcher";
import Selector from "../../../../../../../commonComponents/selectors/Selector/Selector";
import ButtonFileUpload from "../../../../../../../commonComponents/buttons/ButtonFileUpload/ButtonFileUpload";
import ColorPickerInput from "../../../../../../../commonComponents/inputs/ColorPickerInput/ColorPickerInput";
import styled from "styled-components";
import { host } from "../../../../../../../api/const.js";
import { useUserContext } from "../../../../../../../context/userContext.js";

function FormAddOrUpdateTraining({
    trainers,
    initDateOn,
    initDateTo,
    selectedHall,
    handleSuccesCreateTraining,
    handleSuccesUpdateTraining,
    updatingTraining, // если передана тренировка - то автоматически реактирвоание
    trainingSettings,
}) {

    const { userData } = useUserContext();

    const {
        nameTrain, setNameTrain,
        trainer, setTrainer,
        typeTrain, setTypeTrain,
        spaceId, setSpaceId, optionsSpaces,
        numberOfPeople, setNumberOfPeople,
        repeatValue, setRepeatValue, optionsRepeats,
        status, setStatus, optionsStatuses,
        dateOn, setDateOn,
        dateTo, setDateTo,
        optionsTrainingTypes,
        validMessage, setValidMessage,
        isValidForm,
        isLoading,
        onSubmit,
        isAllTrainings, setIsAllTrainings,
        setAvatar,
        discription, setDiscription,
        color, setColor,
        isChoicingImg, setIsChoicingImg,
    } = useLogicFormAddOrUpdateTraining({
        selectedHall,
        handleSuccesCreateTraining,
        handleSuccesUpdateTraining,
        updatingTraining,
        trainingSettings,
        trainersOptions: trainers,
    });

    function checkAcessRole(arrayRole) {
        return arrayRole.includes(userData.role);
    }

    function checkAcessTrainer() {
        return !updatingTraining || optionsTrainingTypes?.some((type) => type.value === typeTrain)
    }

    return (
        <FormAdd
            onSubmit={onSubmit}
            text={!updatingTraining ? 'Новая Тренировка' : 'Редактировать'}
            textButtonSubmit={!updatingTraining ? 'Создать' : 'Редактировать'}
            validationOptions={false} // дисейблить сабмит если true
            textForFill={validMessage}
            cleanTextForFill={() => setValidMessage()}
            isLoading={isLoading}
            buttonUnderText={validMessage}
            isSubmitButton={!isChoicingImg}
            children={
                <>
                    {!isChoicingImg &&
                        <>
                            {(checkAcessRole(['superAdmin', 'admin']) || checkAcessTrainer()) &&
                                <InputText
                                    inputValue={nameTrain}
                                    setInputChange={setNameTrain}
                                    label={'Название'}
                                    placeholder='Введите текст'
                                    isAttention={!nameTrain || nameTrain.length < 1}
                                />}
                            {checkAcessRole(['superAdmin', 'admin']) &&
                                <InputSelect
                                    label={'Тренер:'}
                                    placeholder={'Выберите тренера'}
                                    options={trainers}
                                    value={trainer}
                                    onChange={setTrainer}
                                    isAttention={!trainer}
                                />}
                            {(typeTrain === undefined || checkAcessTrainer()) && // для тренера6 если тип отсутсвует в опциях, значит менять его не может
                                <InputSelect
                                    label={'Тип:'}
                                    placeholder={'Тип тренировки'}
                                    options={optionsTrainingTypes}
                                    value={typeTrain}
                                    onChange={setTypeTrain}
                                    isAttention={!typeTrain}
                                />}
                            {/* <InputSelect
                                label={'Зал:'}
                                placeholder={'Выберите Зал'}
                                options={optionsSpaces}
                                value={spaceId}
                                onChange={setSpaceId}
                            /> */}
                            {checkAcessTrainer() &&
                                <InputText
                                    inputValue={numberOfPeople}
                                    setInputChange={setNumberOfPeople}
                                    label={'Вместимость'}
                                    placeholder='Введите текст'
                                    isAttention={!numberOfPeople || numberOfPeople.length < 1}
                                />}
                            {checkAcessTrainer() &&
                                <InputSelect
                                    label={'Повторы:'}
                                    placeholder={'Выберите повторы'}
                                    options={optionsRepeats ?? []}
                                    value={repeatValue}
                                    onChange={setRepeatValue}
                                    isAttention={!repeatValue}
                                />}
                            <InputSelect
                                label={'Статус:'}
                                placeholder={'Выберите Статус'}
                                options={optionsStatuses}
                                value={status}
                                onChange={setStatus}
                            />
                            {checkAcessTrainer() &&
                                <ColorPickerInput
                                    value={color}
                                    onChange={setColor}
                                />}
                        </>}
                    {checkAcessTrainer() &&
                        <InputDateTimePicker
                            handleSetDateTime={setDateOn}
                            label={'Начало:'}
                            initDate={updatingTraining ? dateOn : initDateOn}
                        />}
                    {checkAcessTrainer() &&
                        <InputDateTimePicker
                            handleSetDateTime={setDateTo}
                            label={'До:'}
                            initDate={updatingTraining ? dateTo : initDateTo}
                        />}
                    {updatingTraining && !isChoicingImg &&
                        <div style={{ marginTop: '10px' }}>
                            <Selector
                                nameOne={'Применить ко всем тренировкам в этот день и время'}
                                handleOnClickOne={() => { setIsAllTrainings(!isAllTrainings) }}
                                switchControl={isAllTrainings} // внешний контроль отображения, true -вкл
                                onlyOn={false} // true - не возвращать селектор при повторном клике, можно только включать
                                styleSelector={'formadd__checkbox_super-admin'}
                            /></div>}
                    {updatingTraining && checkAcessRole(['superAdmin', 'admin']) &&
                        <ContainerPreviewImage style={{ justifyContent: isChoicingImg ? 'center' : 'space-between' }}>
                            <ButtonFileUpload
                                buttonText={'Выбрать Аватар'}
                                onFileChange={setAvatar}
                                isEditing={isChoicingImg}
                                setIsEditing={setIsChoicingImg}
                            />
                            {updatingTraining.image && !isChoicingImg &&
                                <PreviewImage src={`${host}/${updatingTraining.image}`} alt="Preview" />}
                        </ContainerPreviewImage>
                    }
                    {!isChoicingImg && checkAcessTrainer() &&
                        <InputText
                            isMultiline={true}
                            inputValue={discription}
                            setInputChange={setDiscription}
                            label={'Описание тренировки'}
                            placeholder='Введите текст'
                            isRequired={false}
                        />}
                </>
            }
        />
    )
}

export default FormAddOrUpdateTraining;

const PreviewImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 10px;
`;

const ContainerPreviewImage = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;
