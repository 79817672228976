import React from "react";
import InputText from "../../../commonComponents/inputs/InputText/InputText";
import ButtonWithBorder from "../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import useSettingPushClietnBeforeTraining from "../logic/useSettingPushClietnBeforeTraining";

const FormSettingPushClientBeforeTrainingTime = () => {
    const {
        isLoading,
        minutes, setMinutes,
        update,
    } = useSettingPushClietnBeforeTraining();

    return (
        <form>
            <InputText
                inputValue={minutes}
                setInputChange={setMinutes}
                placeholder={'Количество минут'}
                bottomText={''}
                type="number"
                label={'Минут'}
                labelTop={'Уведомление до начала тренировки'}
                isRequired={true}
            />
            <div className="margin-top__30px">
                <ButtonWithBorder
                    text={"Редактировать"}
                    onClick={update}
                    isLoading={isLoading}
                />
            </div>
        </form>
    )
}

export default FormSettingPushClientBeforeTrainingTime;