import React from "react";
import ButtonWithBorder from "../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import Preloader from "../../commonComponents/loaders/Preloader/Preloader";
import SettingsList from "./components/SettingsList/SettingsList"
import useLogicAbonementSettingPage from "./logic/useLogicAbonementSettingPage";
import ModalAbonementSettingsForms from "./components/SettingsList/components/ModalAbonementSettingsForms/ModalAbonementSettingsForms";
import { useStudio } from "../../context/studioContext";
import { useStyle } from "../../context/styleContext";

/** Страница настройки абонементов */
function AbonementSettingPage({ }) {
    const { colorText } = useStyle();
    const {
        isLoading,
        openedForm, setOpenedForm,
        abonementSettings,
        trainingSettings,
        selectedAbonementSettings,
        notActualAbonementSettings,
        handleClickAbonementSettings,
        handleSuccessCreate,
        handleSuccessUpdate,
        handleSuccessRemove,
        validateMessge,
        closeModal,
    } = useLogicAbonementSettingPage();

    return (
        <section className="abonement-setting-page">
            <p className={`title ${colorText}`}>Настройки типов абонементов</p>
            {!isLoading &&
                <div className="margin-top__30px">
                    <ButtonWithBorder
                        text={'Добавить'}
                        onClick={() => setOpenedForm('FormAdd')}
                    />
                </div>}
            {isLoading ?
                <div className="margin-top__30px">
                    <Preloader />
                </div>
                :
                <>
                    {validateMessge ?
                        <p className={`${colorText}`}>{validateMessge}</p>
                        :
                        <SettingsList
                            array={abonementSettings ? abonementSettings : []}
                            handleClickElement={handleClickAbonementSettings}
                        />
                    }
                </>
            }
            <ModalAbonementSettingsForms
                isOpen={['FormAdd', 'FormChoice'].includes(openedForm)}
                isOpenCreateForm={openedForm === 'FormAdd'}
                handleSuccessCreate={handleSuccessCreate}
                handleSuccessUpdate={handleSuccessUpdate}
                handleSuccessRemove={handleSuccessRemove}
                trainingSettings={trainingSettings}
                selectedAbonementSettings={selectedAbonementSettings}
                closeModal={closeModal}
            />
        </section>
    )
}

export default AbonementSettingPage;