import React from "react";
import useLogicFormCompanyBranch from "./logic/useLogicFormCompanyBranch";
import InputText from "../../../../commonComponents/inputs/InputText/InputText"
import InputSelect from "../../../../commonComponents/inputs/InputSelect/InputSelect";
import ButtonFileUpload from "../../../../commonComponents/buttons/ButtonFileUpload/ButtonFileUpload";
import ButtonWithBorder from "../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";

const FormCompanyBranch = () => {

    const {
        name, setName,
        wa, setWA,
        mediaType, setMediaType,
        media, setMedia,
        update,
        isLoading,
    } = useLogicFormCompanyBranch();

    return (
        <form>
            <InputText
                inputValue={name}
                setInputChange={setName}
                placeholder={'Навание'}
                bottomText={''}
                label={'Навание'}
                isRequired={true}
            />
            <InputText
                inputValue={wa}
                setInputChange={setWA}
                placeholder={'WA'}
                bottomText={''}
                label={'WA'}
                isRequired={true}
            />
            <InputSelect
                options={[{ value: 'image', label: 'картинка' }, { value: 'video', label: 'видео' }]}
                onChange={setMediaType}
                inputValue={mediaType}
                placeholder={'Медиа'}
                bottomText={''}
                label={'Медиа'}
                isRequired={true}
            />
            <ButtonFileUpload
                buttonText={mediaType === 'image' ? 'Выбрать картинку' : 'Выбрать видео'}
                onFileChange={setMedia}
                accept={mediaType === 'image' ? "image/*" : "video/*"}
            />
            <div className="margin-top__30px">
                <ButtonWithBorder
                    text={"Редактировать"}
                    onClick={update}
                    isLoading={isLoading}
                />
            </div>
        </form>
    )
}

export default FormCompanyBranch;