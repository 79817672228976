import React, { useState } from "react";
import ButtonWithBorder from "../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import { useStyle } from "../../context/styleContext";
import FormCreateOrUpdate from "./components/Forms/CreateOrUpdate";
import useList from "./hooks/useList";
import Preloader from "../../commonComponents/loaders/Preloader/Preloader";
import List from "./components/List/List";
import useForms from "./hooks/useForms";

const TrainingStatusSettingPage = () => {
    const { colorText } = useStyle();
    const {
        statuses,
        loading: loadingFetchStatuses,
        selectedStatus,
        selectStatus,
    } = useList();
    const {
        form, setForm,
    } = useForms();

    return (
        <>
            <p className={`title ${colorText}`}>Настройки статусов тренировок</p>
            {!loadingFetchStatuses ?
                <>
                    <div className="margin-top__30px">
                        <ButtonWithBorder
                            text={'Добавить'}
                            onClick={() => setForm('create')}
                        />
                    </div>
                    <List
                        items={statuses}
                        handleClickElement={(status) => { selectStatus(status); setForm('update') }}
                    />
                    <FormCreateOrUpdate
                        isOpen={form && ['create', 'update'].includes(form)}
                        updatedStatus={selectedStatus}
                        handleClickClose={() => setForm()}
                    />
                </>
                :
                <div className="margin-top__30px">
                    <Preloader />
                </div>
            }
        </>
    )
}

export default TrainingStatusSettingPage;