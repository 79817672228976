import { useEffect, useState } from "react";
import { formatPhoneNumber } from "./utils/functions";
import { useUserContext } from "../../../../../../../../../../../context/userContext";
import { useStudio } from "../../../../../../../../../../../context/studioContext";
import apiTrainer from "../../../../../../../../../../../api/apiTrainer";

function useLogicFormAddorUpdateTrainers({ isOpen, handleSuccessAdd, handleSuccessUpdate, trainer }) {
    const { userData } = useUserContext();
    const { studio } = useStudio();
    const [nameTrainer, setNameTrainer] = useState('Имя');
    const [subnameTrainer, setSubnameTrainer] = useState('Фамилия');
    const [number, setNumber] = useState('');
    const [email, setEmail] = useState('');
    const [avatar, setAvatar] = useState();
    const [dataTrainer, setDataTrainer] = useState({
        nameTrainer: 'Имя',
        subnameTrainer: 'Фамилия',
        number: '',
        email: 'email'
    })
    const [dataPayment, setDataPayment] = useState();
    const [validDataPayment, setValidDataPayment] = useState(false);
    const [validationMassegeEmail, setValidationMassegeEmail] = useState('Email');
    const [validationMassegeName, setValidationMassegeName] = useState('');
    const [validationMassegeSubname, setValidationMassegeSubname] = useState('');
    const [validationMassegeNumber, setValidationMassegeNumber] = useState('');
    const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
    const [isValidInputs, setIsValidInputs] = useState(false);
    const [isValidMessages, setIsValidMessages] = useState(false);
    const [validMessage, setValidMessage] = useState();
    const [isLoading, setIsLoading] = useState(false);

    function handleChangeName(e) {
        setNameTrainer(e);
    }

    function handleChangeSubname(e) {
        setSubnameTrainer(e);
    }

    function handleChangeNumber(e) {
        let numberFromStr = formatPhoneNumber(e);
        setNumber(numberFromStr);
    }

    function handleChangeEmail(e) {
        setEmail(e);
    }

    async function handleSubmit() {
        try {
            setIsLoading(true);
            let data = {
                name: nameTrainer,
                surname: subnameTrainer,
                companyId: userData.company,
            }
            if (email && email !== "") {
                data.email = email;
            }
            if (number && number !== "") {
                data.phoneNumber = trainer ? number : '7' + number;
            }
            console.log(data)
            if (trainer) {
                data.trainerId = trainer._id;
                const updatedCient = await apiTrainer.updateTrainer(data, avatar)
                trainer = updatedCient;
                handleSuccessUpdate(updatedCient);
            } else {
                data.companyBranchId = studio._id;
                data.dataPayment = JSON.stringify(dataPayment);
                const newTrainer = await apiTrainer.createTrainer(data, avatar)
                handleSuccessAdd(newTrainer);
            }

            setIsLoading(false);
            setValidMessage('');
        } catch (err) {
            setIsLoading(false);
            console.log(err);
            setValidMessage(err.message)
        }

    }

    useEffect(() => {
        setNameTrainer('');
        setSubnameTrainer('');
        setNumber('');
        setEmail('');
        setValidationMassegeName('Имя');
        setValidationMassegeSubname('Фамилия');
        setValidationMassegeNumber('Номер');
        setValidationMassegeEmail('Email');
    }, [isOpen]);

    useEffect(() => {
        if (nameTrainer === "" || subnameTrainer === "") {
            setIsValidInputs(false);
        } else {
            setIsValidInputs(true);
        }
    }, [nameTrainer, subnameTrainer, number, email]);

    useEffect(() => {
        if (
            validationMassegeEmail !== 'Email' ||
            validationMassegeName !== 'Имя' ||
            validationMassegeSubname !== 'Фамилия' ||
            validationMassegeNumber !== 'Номер'
        ) {
            setIsValidMessages(false);
        } else {
            setIsValidMessages(true);
        }
    }, [validationMassegeEmail, validationMassegeName, validationMassegeSubname, validationMassegeNumber]);

    useEffect(() => {
        const validPayment = trainer ? true : !trainer && validDataPayment; // Валидация на переданную оплату только если заводится новый тренер
        if (validPayment && isValidInputs && isValidMessages) {
            setIsSubmitDisabled(false);
        } else {
            setIsSubmitDisabled(true);
        }
    }, [isValidInputs, isValidMessages, validDataPayment]);

    //Если редактирование и есть клиент
    useEffect(() => {
        if (trainer) {
            setNameTrainer(trainer.name);
            setSubnameTrainer(trainer.surname);
            if (trainer?.contactData?.phoneNumber) {
                setNumber(trainer.contactData.phoneNumber);
            }
            if (trainer?.contactData?.email) {
                setEmail(trainer.contactData.email)
            }
        }
    }, [trainer])

    return {
        nameTrainer,
        subnameTrainer,
        number,
        email,
        setDataPayment,
        setValidDataPayment,
        setAvatar,
        isSubmitDisabled,
        handleChangeName,
        handleChangeSubname,
        handleChangeNumber,
        handleChangeEmail,
        handleSubmit,
        isLoading,
        validMessage,
        setValidMessage
    };
}

export default useLogicFormAddorUpdateTrainers;
