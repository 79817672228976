import React, { createContext, useContext, useEffect, useState, } from "react";
import { useStudio } from "./studioContext";

const StyleContext = createContext();

export const StyleContextProvider = ({ children }) => {
    const { studio } = useStudio();
    const [colorText, setColorText] = useState("text-color-flex");
    const [colorTextRGB, setColorTextRGB] = useState('rgba(255, 255, 255, 0.7)');
    const [backgroundColor, setBackgroundColor] = useState("background-color__flex");
    const [backgroundColorRGB, setBackgroundColorRGB] = useState('rgb(44, 44, 46)')
    const [theme, setTheme] = useState('light'); // 'light' 'dark'

    function controlTextColorBytheme(theme) {
        // Выбираем все элементы <p> и <h>
        const paragraphs = document.querySelectorAll("p");
        const headers = document.querySelectorAll("h");

        // Удаляем все классы, начинающиеся на "text-color"
        paragraphs.forEach(p => {
            p.classList.forEach(className => {
                if (className.startsWith("text-color")) {
                    p.classList.remove(className);
                }
            });
        });

        headers.forEach(h => {
            h.classList.forEach(className => {
                if (className.startsWith("text-color")) {
                    h.classList.remove(className);
                }
            });
        });

        setTimeout(() => {
            // Добавляем класс в зависимости от темы
            const classNameText = theme.includes("Flex") ? "text-color-flex" : "text-color-dark";
            // Добавляем класс к элементам <p>
            paragraphs.forEach(p => {
                console.log(p)
                p.classList.add(classNameText);
            });
            // Добавляем класс к элементам <h1> - <h6>
            headers.forEach(h => {
                h.classList.add(classNameText);
            });
        }, 5000)
    }

    function updateTheme(theme) {
        const mainElement = document.querySelector(".App");

        // Удаляем все классы, начинающиеся на "app__"
        mainElement.classList.forEach(className => {
            if (className.startsWith("app__")) {
                mainElement.classList.remove(className);
            }
        });
        // Добавляем класс в зависимости от темы
        if (theme.includes("Flex")) {
            setTheme('light');
            setColorTextRGB('rgb(0,0,0)')
            mainElement.classList.add("app__flex");
            setBackgroundColor("background-color__flex")
            setBackgroundColorRGB('#b4a095')
            setColorText("text-color-flex")
        } else if (theme.includes("DARK")) {
            setTheme('dark');
            setColorTextRGB('rgba(255, 255, 255, 0.7)')
            setBackgroundColorRGB('rgb(44, 44, 46)')
            mainElement.classList.add("app__dark");
            setBackgroundColor("background-color__dark")
            setColorText("text-color-dark")
        }
        controlTextColorBytheme(theme);
    }

    useEffect(() => {
        if (studio) {
            updateTheme(studio.name)
        }
    }, [studio]);



    return (
        <StyleContext.Provider value={{
            theme,
            colorText,
            colorTextRGB,
            backgroundColor,
            backgroundColorRGB,
        }}>
            {children}
        </StyleContext.Provider>
    )

}


export const useStyle = () => {
    return useContext(StyleContext);
};