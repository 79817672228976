import { useEffect, useState } from "react";
//import { getTrainersByCompanyBranchId } from "./functions";
import { useUserContext } from "../../../../../../context/userContext";
import { useStudio } from "../../../../../../context/studioContext";
//import { sortBySurname } from "../../utils/functions";
//import { filterTrainersByString } from "./functions";
import { useNavigate } from "react-router-dom";
import apiAdmin from "../../../../../../api/apiAdmin";
import { sortBySurname } from "../../../../../../utils/listUtils";


function useLogicAdminsList() {
    const { userData } = useUserContext();
    const { studio } = useStudio();
    const [initList, setInitList] = useState(undefined);
    const [list, setList] = useState([]);
    const [openedForm, setOpenedForm] = useState('nameForm');
    const [isLoadingFill, setIsLdingFill] = useState(false);
    const [selectedAdmin, setSelectedAdmin] = useState();

    const navigate = useNavigate();
    function navigateSelctedAdminPage(e, admin) {
        e.preventDefault();
        navigate('/admin', { state: admin });
    }

    //формы
    function openModalWithForm() {
        setOpenedForm('ModalWithForm')
    }

    function clickAdmin(admin) {
        setSelectedAdmin(admin);
        openModalWithForm()
    }

    function addTrainer(data) {
        const updatedList = [...list]
        updatedList.push(data);
        setList(updatedList);
        setOpenedForm();
    }

    function addAdmin(data) {
        const updatedList = [...list]
        updatedList.push(data);
        setList(updatedList);
        setOpenedForm();
    }

    async function fill() {
        try {
            setIsLdingFill(true);
            let data = await apiAdmin.getAdminsByCompanyBranchId(studio._id);
            console.log(data);
            const sortedBuSurname = sortBySurname(data.admins);
            setList(sortedBuSurname);
            setIsLdingFill(false);
        }
        catch (err) {
            setIsLdingFill(false);
        }
    }

    function handleSubmOnSearch(value) {
        if (!initList) {
            setInitList(list);
        }
        setTimeout(() => {
            if (initList) {
                // const filtredTrainers = filterTrainersByString(initList, value);
                //setList(filtredTrainers);
                if (value === '') {
                    setList(initList)
                }
            }
        })
    }

    useEffect(() => {
        fill()
    }, [])

    return {
        list,
        openedForm,
        setOpenedForm,
        addTrainer,
        addAdmin,
        isLoadingFill,
        handleSubmOnSearch,
        navigateSelctedAdminPage,
        openModalWithForm,
        clickAdmin,
        selectedAdmin,
    };
}

export default useLogicAdminsList;
