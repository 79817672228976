import { host } from "./const";

const apiTraining = {
    host: host,

    getTrainingsByCompanyBranchIdAndDateRange({ companyBranchId, dateOn, dateTo }) {
        return fetch(`${this.host}/training-date-range/${companyBranchId}/${dateOn}/${dateTo}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getTrainingById(trainingId) {
        return fetch(`${this.host}/training-by-id/${trainingId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    createTraining(data, avatar) {
        const formData = new FormData();
        // Добавляем все поля из объекта data в formData
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        if (avatar) {
            formData.append('avatar', avatar);
        }

        return fetch(`${this.host}/training`,
            {
                method: 'POST',
                credentials: 'include',
                body: formData,
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    updateTraining(data, avatar) {
        const formData = new FormData();
        // Добавляем все поля из объекта data в formData
        Object.keys(data).forEach(key => {
            formData.append(key, data[key]);
        });
        if (avatar) {
            formData.append('avatar', avatar);
        }
        return fetch(`${this.host}/training`,
            {
                method: 'PATCH',
                credentials: 'include',
                body: formData
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    addClientByIdToVisitList({ clientId, trainingId, companyBranchId }) {
        return fetch(`${this.host}/add-visit-on-training`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ clientId, trainingId, companyBranchId })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    removeClientByIdToVisitList({ clientId, trainingId, companyBranchId }) {
        return fetch(`${this.host}/remove-visit-on-training`,
            {
                method: 'PATCH',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ clientId, trainingId, companyBranchId })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getClientsToTrainingVisitList(trainingId, companyBranchId) {
        return fetch(`${this.host}/training-visit-list/${trainingId}/${companyBranchId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    getTrainingsInRangeByTrainer({ trainerId, dateOn, dateTo, companyBranchId }) {
        return fetch(`${this.host}/training-range-by-trainer/${trainerId}/${dateOn}/${dateTo}/${companyBranchId}`,
            {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    removeTraining({ trainingId, companyBranchId, isAllTrainings }) {
        return fetch(`${this.host}/training`,
            {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ trainingId, companyBranchId, isAllTrainings })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },

    cancelTraining({ trainingId, companyBranchId, notification }) {
        return fetch(`${this.host}/cancel-training`,
            {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify({ trainingId, companyBranchId, notification })
            },)
            .then((res) => {
                if (res.ok) {
                    return res.json();
                } else {
                    return res.json().then((errorJson) => {
                        const error = new Error(errorJson.message === "Validation failed" ? errorJson.validation.body.message : errorJson.message);
                        error.json = errorJson;
                        error.status = res.status;
                        return Promise.reject(error);
                    });
                }
            });
    },
}

export default apiTraining;