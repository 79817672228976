import React from "react";
import styled from "styled-components";
import { useStyle } from "../../context/styleContext";
import AbonementsStatistics from "./components/AbonementsStatistics";

const Statistics = () => {
    const { colorTextRGB } = useStyle();

    return (
        <>
            <Title colorText={colorTextRGB}>Статистика</Title>
            <AbonementsStatistics />
        </>
    );
};

export default Statistics;

const Title = styled.p<TitleProps>`
    color: ${(props) => props.colorText};
    font-size:24px;
    font-weight:700;
`;

interface TitleProps {
    colorText: string;
}