import React from "react";
import { useStyle } from "../../../../../context/styleContext";

/** Статистика по оплате тренеру */
function TrainerPayment({ trainingSummary }) {
    const { colorText } = useStyle();

    return (
        <div className="trainer-statistics__container-columns">
            <div className="trainer-statistics__container-columns__types">
                <p className={`trainer-statistics__text ${colorText}`}>Типы</p>
                {trainingSummary &&
                    trainingSummary.map((tr) => {
                        return <p key={tr.type} className={`trainer-statistics__text ${colorText}`}>{tr.type} </p>
                    })}
            </div>
            <div className="trainer-statistics__container-columns__typesOfPayment">
                <p className={`trainer-statistics__text ${colorText}`}>Оплата</p>
                {trainingSummary &&
                    trainingSummary.map((tr) => {
                        const array = { client: 'за посещение', training: 'за тренировку' }
                        return <p key={array[tr.typePayment]} className={`trainer-statistics__text ${colorText}`}>{array[tr.typePayment]}</p>
                    })}
            </div>
            <div className="trainer-statistics__container-columns__quantity">
                <p className={`trainer-statistics__text ${colorText}`}>₽</p>
                {trainingSummary &&
                    trainingSummary.map((tr) => {
                        const sum = Number(tr.payment) * Number(tr.visits)
                        return <p key={sum.toString()} className={`trainer-statistics__text ${colorText}`}>{sum}</p>
                    })}
            </div>
        </div>
    )
}

export default TrainerPayment;