import React from "react";
import ButtonWithBorder from "../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import InputText from "../../../../../commonComponents/inputs/InputText/InputText";
import useSendPush from "../../../logic/hooks/useSendPush";

const FormSendPush = () => {
    const {
        isLoading,
        title, setTitle,
        text, setText,
        sendPush,
    } = useSendPush();

    return (
        <form>
            <InputText
                labelTop={'Тема'}
                placeholder="Введите тему"
                inputValue={title}
                setInputChange={setTitle}
            />
            <InputText
                labelTop={'Сообщение'}
                placeholder="Введите текст"
                inputValue={text}
                setInputChange={setText}
            />
            <div className="margin-top__30px" />
            <ButtonWithBorder
                text={'Отправить'}
                isLoading={isLoading}
                onClick={sendPush}
            />
        </form>
    )
}

export default FormSendPush;