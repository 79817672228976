import React, { useEffect, useState } from "react";
import styled from "styled-components";

/**
 * Компонент табов на всю ширину экрана.
 *
 * @param {Object} props - Пропсы компонента.
 * @param {Array<{ text: string, onClick: function, mode: string }>} props.tabs - Массив объектов, описывающих каждый таб:
 *  - text: Текст таба.
 *  - onClick: Функция, вызываемая при клике на таб.
 *  - mode: Уникальный идентификатор таба.
 * @param {string} props.initialActiveTab - Значение value таба, который активен по умолчанию.
 * @param {string} props.theme - Тема компонента, может быть "light" или "dark".
 * @returns {JSX.Element} Компонент табов.
 */
const Tabs = ({ tabs, initialActiveTab, theme = "light", onClick }) => {
    const [activeIndex, setActiveIndex] = useState(0);

    useEffect(() => {
        if (initialActiveTab && tabs) {
            const foundIndex = tabs.findIndex(tab => tab.value === initialActiveTab);
            if (foundIndex !== -1) {
                setActiveIndex(foundIndex);
            }
        }
    }, [initialActiveTab, tabs]);

    const handleClick = (index, value) => {
        setActiveIndex(index);
        if (onClick) onClick(value);
    };

    return (
        <TabsContainer>
            {tabs.map((tab, index) => (
                <TabItem
                    key={index}
                    active={index === activeIndex}
                    theme={theme}
                    onClick={() => handleClick(index, tab.value)}
                >
                    {tab.label}
                </TabItem>
            ))}
        </TabsContainer>
    );
};

const TabsContainer = styled.div`
    display: flex;
    width: 100%; /* Растягиваем на всю ширину */
`;

const TabItem = styled.button`
    flex: 1;
    text-align: center;
    background: transparent;
    border: none;
    padding: 12px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: all 0.3s ease;
    border-bottom: ${({ active, theme }) =>
        active
            ? (theme === "light" ? "2px solid black" : "2px solid white")
            : (theme === "light" ? "2px solid rgba(0, 0, 0, 0.09)" : "2px solid rgba(255, 255, 255, 0.1)")};

    color: ${({ active, theme }) => (active ? (theme === "light" ? "black" : "rgba(255, 255, 255, 0.7)") : (theme === "light" ? "rgba(0, 0, 0, 0.4)" : "rgba(255, 255, 255, 0.3)"))};

    &:hover {
        color: ${({ theme }) => (theme === "light" ? "black" : "white")};
    }

    &:focus {
        outline: none;
    }
`;

export default Tabs;