import React from "react";
import ButtonWithBorder from "../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";

/** Форма для выбора работы с тренером */
function FormChoiceActions({
    openUpdateForm,
    openSendPasswordForm,
    openRemoveForm,
}) {
    return (
        <>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Редактировать'} onClick={openUpdateForm} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Отправить пароль'} onClick={openSendPasswordForm} />
            </div>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Удалить'} onClick={openRemoveForm} />
            </div>
        </>
    )
}

export default FormChoiceActions;