import React, { useState, useCallback, useEffect } from "react";
import Cropper from "react-easy-crop";
import imageCompression from "browser-image-compression";
import styled from "styled-components";

interface ImageUploadButtonProps {
  buttonText: string;
  onFileChange: (file: File | null) => void;
  accept?: string;
  isEditing: boolean; // Новый пропс для отслеживания редактирования
  setIsEditing: (value: boolean) => void; // Функция для изменения состояния isEditing
}

const ButtonFileUpload: React.FC<ImageUploadButtonProps> = ({
  buttonText,
  onFileChange,
  accept = "image/*",
  isEditing,
  setIsEditing,
}) => {
  const MAX_FILE_SIZE = 7 * 1024 * 1024;
  const MAX_WIDTH_OR_HEIGHT = 1000;
  const IMAGE_QUALITY = 0.7;

  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>(null);
  const [croppedImage, setCroppedImage] = useState<string | null>(null);
  const [finalFile, setFinalFile] = useState<File | null>(null);

  // Обрабатываем выбор файла
  const handleFileChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0] || null;

    if (!file) return;

    if (file.size > MAX_FILE_SIZE) {
      alert("Размер файла не должен превышать 7 МБ");
      onFileChange(null);
      return;
    }

    // Сжимаем изображение
    const options = {
      maxSizeMB: 1,
      maxWidthOrHeight: MAX_WIDTH_OR_HEIGHT,
      useWebWorker: true,
      initialQuality: IMAGE_QUALITY,
    };

    try {
      const compressedFile = await imageCompression(file, options);
      const imageUrl = URL.createObjectURL(compressedFile);
      setImageSrc(imageUrl);
      setIsEditing(true); // Включаем режим редактирования
    } catch (error) {
      console.error("Ошибка сжатия изображения:", error);
      onFileChange(null);
    }
  };

  // Обновляем область кадрирования
  const onCropComplete = useCallback(async (_: any, croppedAreaPixels: any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  // Функция обрезки изображения
  const getCroppedImg = async () => {
    if (!imageSrc || !croppedAreaPixels) return;

    const image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) return;

    canvas.width = croppedAreaPixels.width;
    canvas.height = croppedAreaPixels.height;

    ctx.drawImage(
      image,
      croppedAreaPixels.x,
      croppedAreaPixels.y,
      croppedAreaPixels.width,
      croppedAreaPixels.height,
      0,
      0,
      croppedAreaPixels.width,
      croppedAreaPixels.height
    );

    canvas.toBlob((blob) => {
      if (blob) {
        const file = new File([blob], "cropped-avatar.png", { type: "image/png" });
        const url = URL.createObjectURL(file);
        setCroppedImage(url);
        setFinalFile(file);
      }
    }, "image/png");
  };

  // Обрезаем автоматически при каждом изменении crop или zoom
  useEffect(() => {
    if (imageSrc && croppedAreaPixels) {
      getCroppedImg();
    }
  }, [crop, zoom, croppedAreaPixels]);

  // Отмена выбора
  const handleCancel = () => {
    setImageSrc(null);
    setCroppedImage(null);
    setFinalFile(null);
    setIsEditing(false); // Выход из режима редактирования
  };

  // Сохранение изображения
  const handleSave = () => {
    if (finalFile) {
      onFileChange(finalFile);
      setImageSrc(null);
      setIsEditing(false); // Завершаем редактирование
    }
  };

  return (
    <Container>
      {!isEditing && (
        <Label htmlFor="image-upload">{buttonText}</Label>
      )}
      <HiddenInput
        type="file"
        accept={accept}
        id="image-upload"
        onChange={handleFileChange}
      />

      {imageSrc && isEditing && (
        <CropContainer>
          <Cropper
            image={imageSrc}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
            cropShape="round"
            showGrid={false}
          />
        </CropContainer>
      )}

      {isEditing && croppedImage && (
        <PreviewContainer>
          <ButtonsRow>
            <SaveButton onClick={handleSave}>Сохранить</SaveButton>
            <CancelButton onClick={handleCancel}>Отмена</CancelButton>
          </ButtonsRow>
        </PreviewContainer>
      )}
    </Container>
  );
};

export default ButtonFileUpload;

// Функция загрузки изображения для Canvas
const createImage = (url: string): Promise<HTMLImageElement> => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.crossOrigin = "anonymous"; // Чтобы избежать проблем с CORS
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.src = url;
  });
};

// Styled-components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Label = styled.label`
  cursor: pointer;
  padding: 10px 20px;
  background: rgb(22, 22, 22);
  color: white;
  border-radius: 5px;
  font-size: 16px;
  text-align: center;
  margin-bottom: 10px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const CropContainer = styled.div`
  position: relative;
  width: 300px;
  height: 300px;
  background: #333;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PreviewContainer = styled.div`
  margin-top: 30px
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ButtonsRow = styled.div`
  display: flex;
  gap: 10px;
`;

const SaveButton = styled.button`
  padding: 10px 20px;
  background: rgb(28, 28, 28);
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
`;

const CancelButton = styled(SaveButton)`
  background: #dc3545;
`;