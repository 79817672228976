import ModalWithChildren from "../../../../../commonComponents/modal/ModalWithChildren";
import FormAddOrUpdateTraining from "./components/FormAddOrUpdateTraining/FormAddOrUpdateTraining";
import FormChoiceActionTraining from "./components/FormChoiceActionTraining/FormChoiceActionTraining";
import useLogicFormTrainig from "./logic/useLogicFormTrainig";
import FormRemoveTraining from "./components/FormRemoveTraining/FormRemoveTraining";
import FormCancelTraining from "./components/FormCancelTraining/FormCancelTraining";

function FormTraining({
    isOpen,
    closeForm,
    trainers,
    initDateOn,
    initDateTo,
    selectedHall,
    handleSuccesCreateTraining,
    handleSuccesUpdateTraining,
    handleSuccessRemoveTraining,
    openTrainingPage,
    selectedTraining,
    trainingSettings,
}) {

    const {
        openedForm,
        setOpenedForm,
        clickCloseForm,
    } = useLogicFormTrainig({ isOpen, selectedTraining, closeForm })

    return (
        <ModalWithChildren
            isOpen={isOpen}
            isClose={clickCloseForm}
            children={
                <>
                    {openedForm === 'FormChoiceActionTraining' && selectedTraining &&
                        <FormChoiceActionTraining
                            nameForm={selectedTraining.name}
                            selectedTraining={selectedTraining}
                            openTrainingPage={openTrainingPage}
                            openCancelForm={() => setOpenedForm('FormCancelTraining')}
                            openUpdateForm={() => setOpenedForm('FormAddOrUpdateTraining')}
                            openRemoveForm={() => setOpenedForm('FormRemoveTraining')}
                        />}
                    {openedForm === 'FormAddOrUpdateTraining' &&
                        <FormAddOrUpdateTraining
                            trainers={trainers}
                            initDateOn={initDateOn}
                            initDateTo={initDateTo}
                            selectedHall={selectedHall}
                            handleSuccesCreateTraining={handleSuccesCreateTraining}
                            handleSuccesUpdateTraining={handleSuccesUpdateTraining}
                            updatingTraining={selectedTraining}
                            trainingSettings={trainingSettings}
                        />
                    }
                    {openedForm === 'FormRemoveTraining' && selectedTraining &&
                        <FormRemoveTraining
                            trainingId={selectedTraining._id}
                            handleSuccessRemoveTraining={handleSuccessRemoveTraining}
                            closeForm={clickCloseForm}
                        />
                    }
                    {openedForm === 'FormCancelTraining' && selectedTraining &&
                        <FormCancelTraining
                            training={selectedTraining}
                            handleSuccess={handleSuccesUpdateTraining}
                            closeForm={clickCloseForm}
                        />
                    }
                </>
            }
        />
    )

}

export default FormTraining;