import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStyle } from "../../../context/styleContext";
import MonthAndYearSelector from "../../../commonComponents/pages/MonthAndYearSelector/MonthAndYearSelector";
import apiStatistics from "../../../api/apiStatistics";
import {useStudio} from "../../../context/studioContext";
import {getMonthAndYear} from '../../../utils/date'

const AbonementsStatistics = () => {
    const { colorTextRGB } = useStyle();
    const { studio: companyBranch } = useStudio();
    const [selectedDate, setSelectedDate] = useState();
    const [abonements, setAbonements] = useState([]);

    async function fetchAbonements() {
        try {
            const res = await apiStatistics.getAbonements(companyBranch._id,)
        }
        catch (err) {

        }
    }

    useEffect(() => {
        console.log(getMonthAndYear(selectedDate), companyBranch)
    }, [selectedDate])

    return (
        <>
            <Title colorText={colorTextRGB}>Абонементы</Title>
            <MonthAndYearSelector
                handleDate={setSelectedDate}
                isFullMonthsAndYears={true}
            />
        </>
    );
}

export default AbonementsStatistics;

const Title = styled.p<TitleProps>`
    color: ${(props) => props.colorText};
    font-size:17px;
    font-weight:700;
`;

interface TitleProps {
    colorText: string;
}