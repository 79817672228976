import { useEffect, useState } from "react";
import apiTrainingStatusSettings from "../../../api/apiTrainingStatusSettings";
import { useStudio } from "../../../context/studioContext";

function useCreateAndUpdate({ updatedStatus }) {
    const { studio } = useStudio();
    const [status, setStatus] = useState('');
    const [description, setDescription] = useState('');
    const [color, setColor] = useState('#FFC0CB');
    const [isLoading, setLoading] = useState(false);
    const [message, setMessage] = useState();

    async function create() {
        try {
            setLoading(true);
            const createObj = {
                companyBranchId: studio._id, status
            }
            if (description) {
                createObj.description = description;
            }
            const res = await apiTrainingStatusSettings.create(createObj);
            return res.trainingStatus;
        }
        catch (err) {
            console.log(err);
            setMessage(err.message ?? err.toString());
        }
        finally {
            setLoading(false);
        }
    }

    useEffect(() => {
        if (updatedStatus) {
            setStatus(updatedStatus.status);
            if (updatedStatus.description) {
                setDescription(updatedStatus.description);
            }
            setColor(updatedStatus.color);
        }
    }, [updatedStatus])

    return ({
        status, setStatus,
        description, setDescription,
        isLoading,
        create,
        message, setMessage,
        color, setColor,
    })
}

export default useCreateAndUpdate;