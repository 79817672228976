import React from "react";
import ModalWithChildren from "../../../../commonComponents/modal/ModalWithChildren";
import FormAddOrUpdate from "../../../../commonComponents/forms/FormAdd/FormAdd";
import InputText from "../../../../commonComponents/inputs/InputText/InputText";
import useCreateAndUpdate from "../../hooks/useCreateAndUpdate";
import ColorPickerInput from "../../../../commonComponents/inputs/ColorPickerInput/ColorPickerInput";

const FormCreateOrUpdate = ({
    isOpen,
    handleClickClose,
    updatedStatus,
    handleSuccess = () => { },
}) => {

    const {
        status, setStatus,
        description, setDescription,
        color, setColor,
        isLoading,
        create,
        message, setMessage,
    } = useCreateAndUpdate({updatedStatus});

    async function submit() {
        const result = updatedStatus ? await create() : await create();
        handleSuccess(result);
    }

    return (
        <ModalWithChildren
            isOpen={isOpen}
            isClose={handleClickClose}
            children={
                <FormAddOrUpdate
                    text={'Статус'}
                    textButtonSubmit={updatedStatus ? 'Редактировать' : 'Создать'}
                    onSubmit={submit}
                    isLoading={isLoading}
                    textForFill={message}
                    cleanTextForFill={() => setMessage()}
                >
                    <InputText
                        label={'Название'}
                        placeholder={'Введите Название'}
                        inputValue={status}
                        setInputChange={setStatus}
                        isRequired={true}
                    />
                    <InputText
                        label={'Описаниe'}
                        placeholder={'Введите Описание'}
                        inputValue={description}
                        setInputChange={setDescription}
                        isRequired={false}
                    />
                    <ColorPickerInput
                        value={color}
                        onChange={setColor}
                    />
                </FormAddOrUpdate>
            }
        />
    )
}

export default FormCreateOrUpdate;