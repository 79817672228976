import React from "react";
import FormSettingPushClientBeforeTrainingTime from "./components/FormSettingPushClientBeforeTraining";
import FormSettingPushClientBeforeTrainingText from "./components/FormSettingPushClientBeforeTrainingText";

const PushSettingsPage = () => {
    return (
        <div>
            <div className="margin-top__30px"/>
            <p className="title">Настроки push уведомлений</p>
            <div className="margin-top__30px"/>
            <FormSettingPushClientBeforeTrainingTime />
            <div className="margin-top__30px"/>
            <FormSettingPushClientBeforeTrainingText />
        </div>
    )
}

export default PushSettingsPage;