import { useStyle } from "../../../../../context/styleContext";
import { formatDateDDMMYY, formatTime } from "../../../../../utils/date";

const VisitInfo = ({ visit }) => {
    const { colorText } = useStyle();
    return (
        <div className='non-membership-visits-page__visit'>
            <p className={`non-membership-visits-page__text ${colorText}`}>{visit.client.surname} {visit.client.name}</p>
            <p className={`non-membership-visits-page__text ${colorText}`}>{formatDateDDMMYY(new Date(visit.training.dateOn))} {formatTime(new Date(visit.training.dateOn))} {visit.training.name}</p>
            <p className={`non-membership-visits-page__text ${colorText}`}>Тренер: {visit.training.trainer.surname} {visit.training.trainer.name}</p>
        </div>
    )
}

export default VisitInfo;