import React from "react";
import FormSettingPushClientBeforeTrainingTime from "./FormSettingPushClientBeforeTrainingTime";

const FormSettingPushClientBeforeTraining = () => {

    return (
        <div>
            <FormSettingPushClientBeforeTrainingTime />
        </div>
    )
}

export default FormSettingPushClientBeforeTraining;