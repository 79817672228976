import ButtonWithBorder from "../../../../../../../commonComponents/buttons/ButtonWithBorder/ButtonWithBorder";
import { useUserContext } from "../../../../../../../context/userContext";

/** Форма для выбора работы с тренировкой */
function FormChoiceActionTraining({
    nameForm,
    selectedTraining,
    openUpdateForm,
    openCancelForm,
    openRemoveForm,
    openTrainingPage,
}) {
    const { userData } = useUserContext();

    function checkAcessRole(arrayRole) {
        return arrayRole.includes(userData.role);
    }

    function checkAcessTrainer() {
        return userData._id === selectedTraining.trainer._id && ['персональная', 'сплит'].includes(selectedTraining.typeOfTraining.type)
    }

    return (
        <>
            <p className="formadd__title">{nameForm}</p>
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Записи'} onClick={openTrainingPage} />
            </div>
            {(checkAcessRole(['superAdmin', 'admin']) || checkAcessTrainer()) &&
                <div className="form-choice-crud__container-button">
                    <ButtonWithBorder text={'Отменить'} onClick={openCancelForm} />
                </div>
            }
            <div className="form-choice-crud__container-button">
                <ButtonWithBorder text={'Редактировать'} onClick={openUpdateForm} />
            </div>
            {(checkAcessRole(['superAdmin', 'admin']) || checkAcessTrainer()) &&
                <div className="form-choice-crud__container-button">
                    <ButtonWithBorder text={'Удалить'} onClick={openRemoveForm} />
                </div>}
        </>
    )
}

export default FormChoiceActionTraining;