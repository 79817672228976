import React from "react";
import styled from "styled-components";
import OutlineButton from "../../../../commonComponents/buttons/OutlineButton/OutlineButton";
import { useUserContext } from "../../../../context/userContext";
import { useStyle } from "../../../../context/styleContext";

/** Компонент отображения информации о стоимост тренировок для тренера (оплаты) */
function TrainerPaymentSettings({ paymentSetting, onClick }) {
    const { userData } = useUserContext();
    const { colorText, theme } = useStyle();
    return (
        <div className="trainer-payment-setting" onClick={onClick}>
            <p className={`trainer-payment-setting__text ${colorText}`}>
                Оплата за группы: {paymentSetting.groupePaymentType === 'client' ? 'за клиента' : 'за тренирвоку'} {paymentSetting.groupePayment} руб
            </p>
            <p className={`trainer-payment-setting__text ${colorText}`}>
                Оплата за перс: {paymentSetting.personalPaymentType === 'procent' ? '' : 'за тренирвоку'} {paymentSetting.personalPayment} %
            </p>
            {userData && ['superAdmin', 'admin'].includes(userData.role) &&
                <ContainerButton>
                    <OutlineButton text={'Настроить'} size='small' onClick={onClick} theme={theme}/>
                </ContainerButton>}
        </div>
    )
}

export default TrainerPaymentSettings;

const ContainerButton = styled.div`
    margin-top: 10px;
    margin-right: auto;
`