import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import ChoiserStudioPage from "../Pages/ChoiserStudioPage/ChoiserStudioPage";
import NotFoundPage from "../Pages/NotFoundPage/NotFoundPage";
import ClientsAndEmployees from "../Pages/ClientsAndEmployees/ClientsAndEmployees";
import Login from "../commonComponents/Login/Login";
import ClientPage from "../Pages/ClientPage/ClientPage";
import TimeTablePage from "../Pages/TimeTablePage/TimeTablePage";
import ClientListOnTrainingPage from "../Pages/ClientListOnTrainingPage/ClientListOnTrainingPage";
import TrainerPage from "../Pages/TrainerPage/TrainerPage";
import TrainingSettingPage from "../Pages/TrainingSettingPage/TrainingSettingPage";
import SettingPage from "../Pages/SettingPage/SettingPage";
import AbonementSettingPage from "../Pages/AbonementSettingPage/AbonementSettingPage";
import AdminPage from "../Pages/AdminPage/AdminPage";
import NonMembershipVisitPage from "../Pages/NonMembershipVisit/NonMembershipVisitPage";
import CompanyBranchSettingPage from "../Pages/CompanyBranchSetting/CompanyBranchSettingPage";
import PushSettingsPage from "../Pages/PushSettings/PushSettingsPage";
import { useUserContext } from "../context/userContext.js";
import { useEffect } from "react";
import { components } from "react-select";
import Statistics from "../Pages/Statistics/Statistics";
import TrainingStatusSettingPage from "../Pages/TrainingStatusSettingPage/TrainingStatusSettingPage.jsx";

// Массив всех маршрутов с ролями
const routes = [
    { path: "/", component: <ClientsAndEmployees />, roles: ["superAdmin", "admin"] },
    { path: "/studio", component: <ChoiserStudioPage />, roles: ["superAdmin", "admin"] },
    { path: "/client", component: <ClientPage />, roles: ["superAdmin", "admin"] },
    { path: "/time-table", component: <TimeTablePage />, roles: ["superAdmin", "admin", "trainer"] },
    { path: "/training-page", component: <ClientListOnTrainingPage />, roles: ["superAdmin", "admin", "trainer"] },
    { path: "/admin", component: <AdminPage />, roles: ["superAdmin", "admin"] },
    { path: "/trainer", component: <TrainerPage />, roles: ["superAdmin", "trainer"] },
    { path: "/setting", component: <SettingPage />, roles: ["superAdmin", "admin"] },
    { path: "/training-setting", component: <TrainingSettingPage />, roles: ["superAdmin", "admin"] },
    { path: "/abonement-setting", component: <AbonementSettingPage />, roles: ["superAdmin", "admin"] },
    { path: "/company-branch-setting", component: <CompanyBranchSettingPage />, roles: ["superAdmin", "admin"] },
    { path: "/push-setting", component: <PushSettingsPage />, roles: ["superAdmin", "admin"] },
    { path: "/training-status-setting", component: <TrainingStatusSettingPage />, roles: ["superAdmin"] },
    { path: "/statistics", component: <Statistics />, roles: ["superAdmin"] },
    { path: "/nonmembershipvisit", component: <NonMembershipVisitPage />, roles: ["superAdmin", "admin"] },
];

// Компонент защиты маршрутов
function ProtectedRoute() {
    const { userData } = useUserContext();

    if (!userData?.role) return <Navigate to="/login" replace />;

    return (
        <Routes>
            {routes.map(({ path, component, roles }) =>
                roles.includes(userData.role) ? <Route key={path} path={path} element={component} /> : null
            )}
        </Routes>
    );
}

/** Точка входа всех роутов */
function MainRoutes() {
    const navigate = useNavigate();
    const { userData } = useUserContext();

    useEffect(() => {
        if (userData.role === 'trainer') {
            navigate("/time-table", { replace: true });
        }
    }, [userData])

    return (
        <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/*" element={<ProtectedRoute />} />
            <Route path="/*" element={<NotFoundPage />} />
        </Routes>
    );
}

export default MainRoutes;