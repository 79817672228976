import { useEffect, useState } from "react";
import { toast } from "react-toastify";

export function useInternet() {
    const [isInternet, setInternet] = useState(navigator.onLine);

    useEffect(() => {
        const checkInternet = () => {
            const onlineStatus = navigator.onLine;
            if (onlineStatus && !isInternet) {
                toast.success("Интернет восстановлен!", { autoClose: 7000 });
            }
            if (!onlineStatus && isInternet) {
                toast.error("Интернет отсутствует!", { autoClose: 7000 });
            }
            setInternet(onlineStatus);
        };

        const interval = setInterval(checkInternet, 10000); // Проверяем каждые 10 секунд
        window.addEventListener("online", checkInternet);
        window.addEventListener("offline", checkInternet);

        return () => {
            clearInterval(interval);
            window.removeEventListener("online", checkInternet);
            window.removeEventListener("offline", checkInternet);
        };
    }, [isInternet]);

    return { isInternet };
}

export default useInternet;