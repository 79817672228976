import React from "react";
import FormAdd from "../../../../../../../../../commonComponents/forms/FormAdd/FormAdd";
import InputText from "../../../../../../../../../commonComponents/inputs/InputText/InputText";
import useLogicFormAddorUpdateAdmins from "./logic/useLogicFormAddorUpdateAdmin"
import InputSelect from "../../../../../../../../../commonComponents/inputs/InputSelect/InputSelect";

function FormAddorUpdateAdmin({
    admin, // если передан то автоматически редактирование
    textButtonSubmit, // текст кнопки сабмита
    handleSuccessAdd, // успешное выполнение
    handleSuccessUpdate,
}) {

    const {
        nameAdmin,
        subnameAdmin,
        number,
        email,
        validMessage,
        setValidMessage,
        isSubmitDisabled,
        handleChangeName,
        handleChangeSubname,
        handleChangeNumber,
        handleChangeEmail,
        handleSubmit,
        isLoading
    } = useLogicFormAddorUpdateAdmins({ handleSuccessAdd, handleSuccessUpdate, admin });

    return (
        <FormAdd
            onSubmit={handleSubmit}
            text={!admin ? 'Добавить админа' : 'Редактировать'}
            textButtonSubmit={textButtonSubmit}
            validationOptions={isSubmitDisabled} // дисейблить сабмит если true
            textForFill={validMessage}
            cleanTextForFill={() => setValidMessage()}
            isLoading={isLoading}
            buttonUnderText={validMessage}
            children={
                <>
                    <InputText
                        inputValue={nameAdmin}
                        setInputChange={handleChangeName}
                        placeholder={"Введите имя"}
                        //bottomText={validationMassegeName}
                        label={"Имя"}
                        isRequired={true}
                    />
                    <InputText
                        inputValue={subnameAdmin}
                        setInputChange={handleChangeSubname}
                        placeholder={"Введите фамилию"}
                        //bottomText={validationMassegeSubname}
                        label={"Фамилия"}
                        isRequired={true}
                    />
                    <InputText
                        inputValue={number}
                        setInputChange={handleChangeNumber}
                        placeholder={"Введите телефон"}
                        //bottomText={validationMassegeNumber}
                        label={"Тел +7"}
                    />
                    <InputText
                        inputValue={email}
                        setInputChange={handleChangeEmail}
                        placeholder={"Введите email"}
                        //bottomText={validationMassegeEmail}
                        label={"Email"}
                        isRequired={true}
                    />
                </>
            }
        />
    )
}

export default FormAddorUpdateAdmin;