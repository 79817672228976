const optionsDate = {
    //timeZone: 'Asia/Yekaterinburg',
    day: 'numeric',
    month: 'numeric',
    year: 'numeric',
};

const optionsTime = {
    //timeZone: 'Asia/Yekaterinburg',
    hour: 'numeric',
    minute: 'numeric',
    hour12: false,
};

const formatterDate = new Intl.DateTimeFormat('ru-RU', optionsDate);
const formatterTime = new Intl.DateTimeFormat('ru-RU', optionsTime);

/**
* Возвращает количество дней между текущей датой и переданной. ( положительно - значит еще не прошла)
* @param {Date} date - Переданная дата.
* @returns {number} Количество дней.
*/
function getDaysDifference(date) {
    // Получаем сегодняшнюю дату
    const today = new Date();

    // Разница в миллисекундах между переданной датой и сегодняшней датой
    const differenceMilliseconds = date.getTime() - today.getTime();

    // Преобразуем разницу в дни
    const differenceDays = Math.floor(differenceMilliseconds / (1000 * 60 * 60 * 24));

    return differenceDays;
}

/** Функция для форматирования даты в формате DD.MM.YY */
function formatDateDDMMYY(data) {
    const date = new Date(data)
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = String(date.getFullYear()).slice(-2);
    return `${day}.${month}.${year}`;
}

function formatTime(data) {
    const date = new Date(data)
    const formatedTime = formatterTime.format(date);
    return formatedTime;
}

function sortByDate(array) {
    const sortedByDate = array.sort((a, b) => new Date(b.dateOn) - new Date(a.dateOn))
    return sortedByDate;
}

/**
 * Получает номер месяца (где январь = 1) и год из переданной даты.
 *
 * @param {Date|string|number} date - Дата в формате `Date` объекта, строки (ISO 8601) или timestamp.
 * @returns {{ month: number, year: number }} Объект с номером месяца (1-12) и годом.
 */
function getMonthAndYear(date) {
    const parsedDate = new Date(date); // Создаем объект Date
    const month = parsedDate.getMonth() + 1; // Январь = 1, Февраль = 2, и так далее
    const year = parsedDate.getFullYear(); // Получаем год

    return { month, year };
}

export {
    getDaysDifference,
    formatDateDDMMYY,
    formatTime,
    sortByDate,
    getMonthAndYear,
}