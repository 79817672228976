import React from "react";
import ListWithOnClickElement from "../../../../commonComponents/pages/ListWithOnClickElement/ListWithOnClickElement";
import SettingElementList from "./SettingElementList";

const List = ({ items, handleClickElement }) => {
    return (
        <div className="margin-top__30px width_100procents">
         <ListWithOnClickElement
                array={items}
                handleClickOnElement={handleClickElement}
                children={(item) => <SettingElementList element={item} />}
            />
        </div>
    )
}

export default List;