import React from "react";
import { formatTime } from "../../../utils/date";
import { useStyle } from "../../../context/styleContext";

function TitleClientListOnTrainingPage({ training }) {
    const { colorText } = useStyle();

    return (
        <p className={`client-list-on-training-page__title font-weight-bold ${colorText}`}>{`
                ${training?.name}
                c 
                ${training ? formatTime(training?.dateOn) : ''}
                до 
                ${training ? formatTime(training?.dateTo) : ''} 
                `}
        </p>
    )
}

export default TitleClientListOnTrainingPage;