import React from "react";
import { useStudio } from "../../../../context/studioContext";
import { useStyle } from "../../../../context/styleContext";

/** Элемент списка настроек статусов тренировок */
function SettingElementList({ element }) {
    const { colorText } = useStyle();

    return (<div className='width_100procents display-flex flex-direction_column'>
        <div className='display-flex justify-content_space-between'>
            <p className={`client-info__text font-weight-bold ${colorText.colorText}`}>
                Название
            </p>
            <p className={`client-info__text font-weight-bold ${colorText.colorText}`}>
                {element.status}
            </p>
        </div>
        <div className='display-flex justify-content_space-between'>
            <p className={`client-info__text font-weight-bold ${colorText.colorText}`}>
                Описание
            </p>
            <p className={`client-info__text font-weight-bold ${colorText.colorText}`}>
                {element.description}
            </p>
        </div>
    </div>
    )
}
export default SettingElementList;