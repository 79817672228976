import { useState } from 'react';
import './App.css';
import MainPage from './Pages/MainPage/MainPage';
import MainRoutes from './Routes/MainRoutes';
import { StudioContextProvider } from './context/studioContext';
import { StyleContextProvider } from './context/styleContext';
import { UserContextProvider } from './context/userContext';
import { ThemeProvider as ThemeProviderGravityUI } from '@gravity-ui/uikit';
import { ToastContainer } from 'react-toastify';
import useInternet from "./hooks/useInternet"
// localStorage.clear();

function App() {
  useInternet();

  return (
    <main className="App">
      <ThemeProviderGravityUI theme="light">
        <UserContextProvider>
          <StudioContextProvider>
            <StyleContextProvider>
              <MainPage />
              <ToastContainer position="top-right" theme="dark"/>
            </StyleContextProvider>
          </StudioContextProvider>
        </UserContextProvider>
      </ThemeProviderGravityUI>,
    </main>
  );
}

export default App;
