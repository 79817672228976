import ModalWithChildren from "../../../../../commonComponents/modal/ModalWithChildren";
import FormAddorUpdateClient from "../../../../ClientsAndEmployees/components/List/ClientsList/components/forms/FormAddClient/FormAddorUpdateClient";
import FormSendPush from "../FormSendPush/FormSendPush";
import FormChoiceActionClient from "./FormChoiceActionClient/FormChoiceActionClient";
import FormRefreshPassword from "./FormRefreshPassword/FormRefreshPassword";
import useLogicFormClient from "./logic/useLogicFormClient";

/** Форма работы с абонементом ( создать или редактировать или удалить) */
function FormClient({
    isOpen,
    closeForm,
    client,
    handleSuccessUpdate,
}) {
    const {
        openedForm,
        setOpenedForm,
        clickCloseForm,
        handleSuccessUpdateClient,
        handleCreate,
        handleAbonementRemove,
    } = useLogicFormClient({
        client,
        closeForm,
        handleSuccessUpdate,
    });

    return (
        <ModalWithChildren
            // onSubmit={onSubmit} // сабмит
            isOpen={isOpen} // стейт контролирующий открытие
            isClose={clickCloseForm} // клик на кнопку для сворачивания
            children={
                <>
                    {openedForm === 'FormChoiceActionClient' &&
                        < FormChoiceActionClient
                            openUpdateForm={() => setOpenedForm('FormAddorUpdateClient')}
                            openPasswordRefreshForm={() => setOpenedForm('FormRefreshPassword')}
                            openSendPushForm={() => setOpenedForm('FormSendPush')}
                        />}
                    {openedForm === 'FormAddorUpdateClient' &&
                        <FormAddorUpdateClient
                            client={client}
                            textButtonSubmit={'Редактировать'}
                            handleSuccessUpdate={handleSuccessUpdateClient}
                        />
                    }
                    {openedForm === 'FormRefreshPassword' &&
                        <FormRefreshPassword
                            client={client}
                            closeForm={clickCloseForm}
                        />
                    }
                    {openedForm === 'FormSendPush' &&
                        <FormSendPush />
                    }
                </>
            }
        />
    )
}

export default FormClient;