import { Calendar as RBCalendar, dayjsLocalizer } from 'react-big-calendar';
import dayjs from 'dayjs';
import 'dayjs/locale/ru'; // Импортируйте русскую локализацию
import 'react-big-calendar/lib/css/react-big-calendar.css';
import { useEffect, useState } from 'react';
import { useStyle } from '../../context/styleContext';

const localizer = dayjsLocalizer(dayjs)

function Calendar({
    arrayEvents,
    handleSelectEvent,
    handleSelectSlot,
    handleRangeDateChange,
}) {

    const [events, setEvents] = useState([]);
    const { backgroundColorRGB, colorTextRGB } = useStyle();

    useEffect(() => {
        const header = document.querySelector(".rbc-time-header");
        const buttons = document.querySelectorAll(".rbc-calendar button")
        const labels = document.querySelectorAll(".rbc-label")
        //const columtitle = document.querySelector()
        if (header) {
            header.style.backgroundColor = backgroundColorRGB; // Добавляем фон
        }
        if (buttons) {
            buttons.forEach((button) => {
                button.style.color = colorTextRGB;
            })
        }
        if (labels) {
            labels.forEach((button) => {
                button.style.color = colorTextRGB;
            })
        }
    }, [backgroundColorRGB])

    // Получить локаль и перевод
    dayjs.locale('ru')

    //     useEffect(() => {
    //         if (arrayEvents && arrayEvents.length > 0) {

    //             const events = arrayEvents.map(training => {
    //                 console.log(training)
    //                 //Инф о тренере
    //                 let trainerName;
    //                 if (training.trainer) {
    //                     const firstLetter = training.trainer.surname[0];
    //                     trainerName = `${training.trainer.name} ${firstLetter}`;
    //                 } else {
    //                     trainerName = 'тренер'
    //                 }

    //                 const name = `${training.name} ${trainerName}`;
    //                 const nameTrainer = trainerName;
    //                 const start = new Date(training.dateOn);
    //                 const end = new Date(training.dateTo);

    //                 return {
    //                     id: training._id,
    //                     title: name,
    //                     start: start,
    //                     end: end,
    //                 }
    //             });
    // console.log(events)
    //             setEvents(events);

    //         }
    //     }, [arrayEvents])

    //Стиль ячеек
    const getEventStyle = (event) => {
        const backgroundColor = event.color ?? 'blue';
        const style = {
            backgroundColor: backgroundColor,
        };

        return {
            style: style,
        };
    };

    function selectEvent(e) {
        handleSelectEvent(e);
    }

    const eventContentRenderer = ({ event }) => (
        <div>
            <div className='rbc-event-label'>{event.title}</div>
            <div className='rbc-event-content'>{event.trainerName}</div>
            <div className='rbc-event-content'>{event.filling}</div>
        </div>
    );

    const messages = {
        allDay: 'весь день',
        previous: 'предыдущий',
        next: 'следующий',
        today: 'сегодня',
        month: 'месяц',
        week: 'неделя',
        day: 'день',
        agenda: 'повестка дня',
        date: 'дата',
        time: 'время',
        event: 'событие',
    };

    // Устанавливаем свои названия дней недели
    const customWeekdayFormat = (date, culture, localizer) => {
        const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        return days[date.getDay()]; // Замените на свои названия дней недели
    };

    // Получение текущей даты
    const currentDate = new Date();

    // Установка времени на 07:00
    const minTime = new Date(currentDate);
    minTime.setHours(7, 0, 0, 0);

    // Установка времени на 23:59:59
    const maxTime = new Date(currentDate);
    maxTime.setHours(23, 59, 59, 999);

    // Обработчик выбора пустой ячейки
    function selectSlot(slotInfo) {
        console.log(slotInfo);
        if (handleSelectSlot) {
            handleSelectSlot(slotInfo);
        }
    }

    //Обработчик переключения дат ( возвращает массив дат)
    function changeRange(range) {
        if (handleRangeDateChange) {
            handleRangeDateChange(range);
        }
    }

    // Компонент для отображения заголовка с полными названиями дней недели
    const CustomDayHeader = ({ label }) => (
        <p>{label}</p>
    );


    return (
        <div className='calendar'>
            <RBCalendar
                localizer={localizer}
                step={60} // Разделение ячеек по 60 минутам (1 час)
                timeslots={1}
                events={arrayEvents ? arrayEvents : []}
                //eventStyleGetter={getEventStyle}
                selectable={true} // Разрешает обрабатывать клик на пустую ячейку
                onSelectEvent={selectEvent} // Обработчик клика на событии
                onSelectSlot={selectSlot} // Обработчик выбора пустой ячейки
                defaultView='week'
                views={['week', 'day']}
                messages={messages}
                min={minTime} // Преобразование в объект Date
                max={maxTime} // Преобразование в объект Date
                eventPropGetter={getEventStyle} // Применяем функцию getEventStyle
                onRangeChange={changeRange}
                components={{
                    event: eventContentRenderer,
                }}
            />
        </div>
    )
}

export default Calendar;